<template>
  <div class="pdf-doc">
    <svg xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
			<symbol id="icon-gamela" fill="currentColor" viewBox="0 0 35.32 24.17">
				<g id="Capa_2" data-name="Capa 2">
					<g id="Capa_1-2" data-name="Capa 1">
						<path class="cls-1" d="M7.74,19.19h18a3.07,3.07,0,0,0,3.06-3.07v-13A3.07,3.07,0,0,0,25.74,0H3.07A3.07,3.07,0,0,0,.18,4.15l4.67,13A3.07,3.07,0,0,0,7.74,19.19Zm-5.95-17a1.52,1.52,0,0,1,1.28-.67H25.74A1.57,1.57,0,0,1,27.3,3.11v13a1.56,1.56,0,0,1-1.56,1.57h-18a1.58,1.58,0,0,1-1.48-1l-4.67-13A1.55,1.55,0,0,1,1.79,2.21Z"/>
						<path class="cls-1" d="M34.57,0h-1.5a2.83,2.83,0,0,0-2.83,2.83v15a.75.75,0,0,0,1.5,0v-15A1.34,1.34,0,0,1,33.07,1.5h1.5a.75.75,0,0,0,0-1.5Z"/><path class="cls-1" d="M28,20.39H7.15a3,3,0,0,0-3,2.72.77.77,0,0,0,0,.15v.16a.75.75,0,0,0,1.5,0,1.54,1.54,0,0,1,1.53-1.53H28a1.53,1.53,0,0,1,1.52,1.53.75.75,0,0,0,1.5,0v-.16a.76.76,0,0,0,0-.15A3,3,0,0,0,28,20.39Z"/>
						<path class="cls-1" d="M10.58,11.15a.58.58,0,0,0-.19,0H7.66a.75.75,0,0,0,0,1.5h2.73a.58.58,0,0,0,.19,0v1.34a.75.75,0,0,0,1.5,0V10H14.9v3.87a.75.75,0,1,0,1.5,0V10h2.82v4a.75.75,0,0,0,1.5,0V10h2.82v3.87a.75.75,0,1,0,1.5,0V5.2a.75.75,0,1,0-1.5,0V8.54H20.72V5.38a.75.75,0,1,0-1.5,0V8.54H16.4V5.2a.75.75,0,0,0-1.5,0V8.54H12.08V5.2a.75.75,0,0,0-1.5,0V6.32H5.66a.75.75,0,0,0,0,1.5h4.92Z"/>
					</g>
				</g>
			</symbol>
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
			<symbol id="icon-gamela-pesada" fill="currentColor" viewBox="0 0 39.22 36.12">
				<g id="Capa_2" data-name="Capa 2">
					<g id="Capa_1-2" data-name="Capa 1">
						<path class="cls-1" d="M7.74,31.14h18a3.06,3.06,0,0,0,3.06-3.06v-13A3.07,3.07,0,0,0,25.74,12H3.07A3.06,3.06,0,0,0,.56,13.3a3,3,0,0,0-.38,2.8l4.67,13A3.06,3.06,0,0,0,7.74,31.14Zm-5.95-17a1.54,1.54,0,0,1,1.28-.67H25.74a1.57,1.57,0,0,1,1.56,1.57v13a1.56,1.56,0,0,1-1.56,1.56h-18a1.56,1.56,0,0,1-1.48-1l-4.67-13A1.55,1.55,0,0,1,1.79,14.16Z"/>
						<path class="cls-1" d="M34.57,12h-1.5a2.84,2.84,0,0,0-2.83,2.84v15a.75.75,0,0,0,1.5,0v-15a1.34,1.34,0,0,1,1.33-1.34h1.5a.75.75,0,0,0,0-1.5Z"/><path class="cls-1" d="M28,32.35H7.15a3,3,0,0,0-3,2.71.77.77,0,0,0,0,.15v.16a.75.75,0,0,0,1.5,0,1.53,1.53,0,0,1,1.53-1.52H28a1.52,1.52,0,0,1,1.52,1.52.75.75,0,0,0,1.5,0v-.16a.76.76,0,0,0,0-.15A3,3,0,0,0,28,32.35Z"/>
						<path class="cls-1" d="M10.58,23.1a.58.58,0,0,0-.19,0H7.66a.75.75,0,0,0,0,1.5h2.73a.58.58,0,0,0,.19,0v1.34a.75.75,0,0,0,1.5,0V22H14.9v3.87a.75.75,0,1,0,1.5,0V22h2.82v4a.75.75,0,0,0,1.5,0V22h2.82v3.87a.75.75,0,0,0,1.5,0v-8.7a.75.75,0,0,0-1.5,0v3.33H20.72V17.33a.75.75,0,0,0-1.5,0v3.16H16.4V17.16a.75.75,0,0,0-1.5,0v3.33H12.08V17.16a.75.75,0,0,0-1.5,0v1.11H5.66a.75.75,0,0,0,0,1.5h4.92Z"/>
						<path class="cls-1" d="M37.7,2.65a1.06,1.06,0,0,0,.36-.47,1.17,1.17,0,0,0,0-.88A1.14,1.14,0,0,0,37.4.7a1.08,1.08,0,0,0-.88,0,1.1,1.1,0,0,0-.61.64,2,2,0,0,0,0,.26L33.31,2.76V2.11a1.14,1.14,0,0,0,.55-1,1.15,1.15,0,0,0-2.3,0,1.12,1.12,0,0,0,.55,1V3.29L28.88,4.73a1.18,1.18,0,0,0-.22-.14,1.17,1.17,0,0,0-.88,0,1.11,1.11,0,0,0-.6.64,1.08,1.08,0,0,0,0,.88,1,1,0,0,0,.27.36L26,9.44l0,.21a2,2,0,0,0,2.18,1.41,2.13,2.13,0,0,0,2.09-1.34l.08-.24-1.46-2.9a1.16,1.16,0,0,0,.4-.5,1.09,1.09,0,0,0,0-.25l2.74-1.22V9.26h1.2V4.07l3-1.33L34.83,5.65l0,.2a2,2,0,0,0,2.18,1.41,2.14,2.14,0,0,0,2.09-1.33l.08-.24ZM28.22,9.86a1.15,1.15,0,0,1-.93-.32l.89-1.78.88,1.75A1,1,0,0,1,28.22,9.86Zm8.83-3.8a1.16,1.16,0,0,1-.93-.31L37,4l.88,1.75A1.05,1.05,0,0,1,37.05,6.06Z"/>
					</g>
				</g>
			</symbol>
		</svg>
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
			<symbol id="icon-Vehicle-Tara" fill="currentColor" viewBox="0 0 34.18 19.63">
				<g id="Capa_2" data-name="Capa 2">
					<g id="Capa_1-2" data-name="Capa 1">
						<path d="M27,19.63a3.31,3.31,0,1,1,3.41-3.31A3.37,3.37,0,0,1,27,19.63Zm0-5.12a1.81,1.81,0,1,0,1.91,1.81A1.86,1.86,0,0,0,27,14.51Z"/>
						<path d="M8.26,19.51a3.31,3.31,0,1,1,0-6.61,3.31,3.31,0,1,1,0,6.61Zm0-5.11a1.81,1.81,0,1,0,0,3.61,1.81,1.81,0,1,0,0-3.61Z"/>
						<path d="M32.11,16.31a.75.75,0,0,1,0-1.5.57.57,0,0,0,.57-.58V7.08a.49.49,0,0,0,0-.12L31,2a.62.62,0,0,0-.58-.54H22.12a.58.58,0,0,0-.58.58V14.23a.58.58,0,0,0,.58.58.75.75,0,0,1,0,1.5A2.08,2.08,0,0,1,20,14.23V2.08A2.08,2.08,0,0,1,22.12,0h8.25a2.08,2.08,0,0,1,2,1.63l1.72,4.92a1.92,1.92,0,0,1,.07.53v7.15A2.08,2.08,0,0,1,32.11,16.31Z"/>
						<path d="M2.93,16.31H.75a.75.75,0,1,1,0-1.5H2.93a.75.75,0,1,1,0,1.5Z"/>
						<path d="M18.42,16.31H13.65a.75.75,0,1,1,0-1.5h4.77a.75.75,0,0,1,0,1.5Z"/>
						<path d="M30.66,9a.75.75,0,0,1-.72-.52l-1.2-3.93a.28.28,0,0,0-.28-.27H24.94a.75.75,0,0,1,0-1.5h3.52A1.76,1.76,0,0,1,30.23,4.4L31.37,8a.74.74,0,0,1-.49.94A.83.83,0,0,1,30.66,9Z"/>
					</g>
				</g>
			</symbol>
		</svg>
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-vessel">
                <div class="text-center mt-5">
                  <h2 style="color: #1a3760 !important; font-weight: bold;">{{ $t('label.statusVisit') }}</h2>
                </div>
                <div class="box-center">
                  <div class="info-Situation mb-2 p-4">
                    <div style="display: flex; font-weight: 500 !important; width: 100%;">
                      <div class="wrapper-box mr-2 mb-4" style="width: 33.33%; background: #b2ccf0 !important;">
                        <CRow class="vessel-date">
                          <div style="font-size:smaller">
                            <strong>{{ $t('label.StartOperations') }}</strong>
                          </div>
                          <div style="width: 40%;">
                            {{ ValidateDate(VesselSituation?.VesselSituationJson[0]?.BeginOpsDate) }}
                          </div>
                        </CRow>
                        <CRow class="vessel-date">
                          <div style="font-size:smaller">
                            <strong>{{ 'EST. '+$t('label.endOperation') }}</strong>
                          </div>
                          <div style="width: 40%;">
                            {{ ValidateDate(VesselSituation?.VesselSituationJson[0]?.EstDepartureDate) }}
                          </div>
                        </CRow>
                      </div>
                      <div class="wrapper-box mr-2 mb-4" style="width: 33.33%; background: #efaa86 !important;">
                        <CRow class="vessel-date">
                          <div style="font-size:smaller">
                            <strong>{{ $t('label.Berthed') }}</strong>
                          </div>
                          <div style="width: 45%;">
                            {{ ValidateTime(VesselSituation?.VesselSituationJson[0]?.TimeJson[0]?.ArrivalDsEn) }}
                          </div>
                        </CRow>
                        <CRow class="vessel-date">
                          <div style="font-size:smaller">
                            <strong>{{ $t('label.EstimatedDeparture') }}</strong>
                          </div>
                          <div style="width: 45%;">
                            {{ ValidateDate(VesselSituation?.VesselSituationJson[0]?.Etd) }}
                          </div>
                        </CRow>
                      </div>
                      <div class="wrapper-box mb-4" style="width: 33.33%; background: #bdbdbd !important;">
                        <CRow class="vessel-date">
                          <div style="font-size:smaller">
                            <strong>{{ $t('label.Retraso') }}</strong>
                          </div>
                          <div style="width: 45%;">
                            {{ ValidateTime(VesselSituation?.VesselSituationJson[0]?.TimeJson[0]?.DelayDsEn) }}
                          </div>
                        </CRow>
                        <CRow class="vessel-date">
                          <div style="font-size:smaller">
                            <strong>{{ $t('label.HoursOperating') }}</strong>
                          </div>
                          <div style="width: 45%;">
                            {{ ValidateTime(VesselSituation?.VesselSituationJson[0].TimeJson[0]?.OpsDsEn) }}
                          </div>
                        </CRow>
                      </div>
                    </div>
                    <div style="display: flex; width: 100%;">
                      <div class="wrapper-box vessel-row mr-2 mb-4" style="width: 33.33%;">
                        <svg class="flex-shrink-0 me-2 mr-2" width="50" height="50" role="img" aria-label="watch:" ><use xlink:href="#icon-gamela"/></svg>
                        <div style="width: 60%;">
                          <div class="row">
                            <div class="col-sm-6 text-right">
                              <h3 class="input-Bin mb-0">
                                {{ ValueData(VesselSituation?.VesselSituationJson[0]?.ImplementEmbarked) }}
                              </h3>
                            </div>
                            <strong class="col-form-label-sm mt-1">{{ $t('label.Loadeds') }}</strong>
                          </div>
                          <div class="row">
                            <div class="col-sm-6 text-right">
                              <h3 class="input-Bin mb-0">
                                {{ ValueData(VesselSituation?.VesselSituationJson[0]?.ImplementReturned) }}
                              </h3>
                            </div>
                            <strong class="col-form-label-sm m-1">{{ $t('label.Returned') }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="wrapper-box vessel-row mr-2 mb-4" style="width: 33.33%;">
                        <svg class="flex-shrink-0 me-2 mr-2" width="50" height="50" role="img" aria-label="watch:"><use xlink:href="#icon-Vehicle-Tara"/></svg>
                        <div style="width: 60%;">
                          <div class="row">
                            <div class="col-sm-6 text-right">
                              <h3 class="input-transport mb-0">
                                {{ ValueData(VesselSituation?.VesselSituationJson[0]?.ImplementInCircuit) }}
                              </h3>
                            </div>
                            <strong class="col-form-label-sm mt-1">{{ $t('label.onCircuit') }}</strong>
                          </div>
                          <div class="row">
                            <div class="col-sm-6 text-right">
                              <h3 class="input-transport mb-0">
                                {{ ValueData(VesselSituation?.VesselSituationJson[0]?.CantIncidenceCarrier) }}
                              </h3>
                            </div>
                            <strong class="col-form-label-sm mt-1">{{ $t('label.Inoperative') }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="wrapper-box vessel-row mb-4" style="width: 33.33%;">
                        <svg class="flex-shrink-0 me-2 mr-2" width="50" height="50" role="img" aria-label="watch:" ><use xlink:href="#icon-gamela-pesada"/></svg>
                        <div style="width: 60%;">
                          <div class="row">
                            <div class="col-sm-6 text-right">
                              <h3 class="input-heavy mb-0">
                                {{ ValueData(VesselSituation?.VesselSituationJson[0]?.ImplementWeighed) }}
                              </h3>
                            </div>
                            <strong class="col-form-label-sm mt-1">{{ $t('label.Heavy') }}</strong>
                          </div>
                          <div class="row">
                            <div class="col-sm-6 text-right">
                              <h3 class="input-heavy mb-0">
                                {{ ValueData(VesselSituation?.VesselSituationJson[0]?.ImplementNotWeighed) }}
                              </h3>
                            </div>
                            <strong class="col-form-label-sm mt-1">{{ $t('label.NoWeighed') }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; font-weight: 500 !important; width: 100%;">
                      <div class="wrapper-box mr-2 mb-4" style="width: 50%;">
                        <div>
                          <label style="box-shadow: #ec631b 0px 1px 0px 0px !important;" class="col-sm-6 pl-1">TON</label>
                          <label style="box-shadow: #ec631b 0px 1px 0px 0px !important;" class="col-sm-6">{{ $t('label.Gamela') }}</label>
                        </div>
                        <CRow>
                          <CCol sm="6">
                            <label>{{ $t('label.PerHour') }}: <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.TonXHour) }}</strong></label><br>
                            <label>{{ $t('label.PerDay') }}: <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.TonXDay) }}</strong></label><br>
                            <label>{{ $t('label.GamelaXdia') }}: <strong>{{ ValueData(VesselSituation?.VesselSituationJson[0]?.ImplementXDay) }}</strong></label><br>
                          </CCol>
                          <CCol sm="6">
                            <label>{{ $t('label.Transferencia') }}: <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.Transference) }}</strong></label><br>
                            <label>{{ $t('label.BinEstXCulm') }}: <strong>{{ ValueData(VesselSituation?.VesselSituationJson[0]?.RemainingImplement) }}</strong></label><br>
                            <label>TON {{ $t('label.PerGamela') }}: <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.TonPromXImplement) }}</strong></label><br>
                          </CCol>
                        </CRow>
                      </div>
                      <div class="wrapper-box mb-4" style="width: 50%;">
                        <CRow>
                          <CCol sm="4" class="pr-0">
                            <label class="mb-0">{{ $t('label.PlannedTon') }}</label><br>
                            <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.TonPlanif) }}</strong><br>
                            <label class="mb-0">{{ $t('label.CertifiedTon') }}</label><br>
                            <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.CertificateTon) }}</strong><br>
                            <label class="mb-0">{{ $t('label.TonLoaded') }}</label><br>
                            <div class="input-Bin">{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.TonEmbarked) }}</div><br>
                          </CCol>
                          <CCol sm="4" class="pr-0">
                            <label class="mb-0">{{ $t('label.WeighedTon') }}</label><br>
                            <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.WeighedTon) }}</strong><br>
                            <label class="mb-0">{{ $t('label.LastReading') }}</label><br>
                            <strong>{{ ValidateDate(VesselSituation?.VesselSituationJson[0]?.LastReadDate) }}</strong><br>
                            <label class="mb-0">{{ $t('label.LastLoad') }}</label><br>
                            <strong>{{ ValidateDate(VesselSituation?.VesselSituationJson[0]?.LastEmbarkedDate) }}</strong><br>
                          </CCol>
                          <CCol sm="4">
                            <label class="mb-0">TOTAL {{ $t('label.voyage')+'S' }}</label><br>
                            <strong style="color: #1e3a61;">{{ ValueData(VesselSituation?.VesselSituationJson[0]?.TotalVoyages) }}</strong><br>
                            <label class="mb-0">{{ $t('label.RemainingTon') }}</label><br>
                            <strong>{{ NumberValue(VesselSituation?.VesselSituationJson[0]?.TonRemaining) }}</strong><br>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+1 }} {{ $t("label.of") }} {{ 1 }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import DataStorage from "@/_helpers/localStorage";
//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function NumberValue(val) {
	if (val) {
		return NumberFormater.formatNumber(val,2);
	} else {
		return NumberFormater.formatNumber(0,2);
	}
}

function ValueData(val) {
	if (val) {
		return val
	} else {
		return 0;
	}
}

function ValidateDate(date) {
	if (date) {
		return DateFormater.formatDateTimeWithSlash(date);
	} else {
		return 'N/A';
	}
}

function ValidateTime(time) {
	if (time) {
		return time;
	} else {
		return 'N/A';
	}
}
//Computeds:
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function currentDateTime() {
  let today = new Date();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let date = today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleCompany() {
 let CompanyName = this.user.CompanyName;
 let CompanyRif = this.user.CompanyRif;
 let BranchName = this.branch.BranchName;

  return `${CompanyName} ${CompanyRif} - ${BranchName}`;
}
function titleVessel() {
 let VesselName = this.itinerarySelected.VesselName;
 let Imo = this.itinerarySelected.Imo;
 let CountryName = this.itinerarySelected.CountryName;
 let berth = this.$t('label.berth');
 let BerthName = this.itinerarySelected.BerthName;

  return `${VesselName} ${Imo}, ${CountryName}, ${berth}: ${BerthName}`;
}
function titleFromItinerary() {
  let previousDate;
  let laterDate;
  let fecha = "";
  let voyage = this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;

  if(this.itinerarySelected.Ata!=undefined){ 
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    previousDate = "ATA:"+fecha;
  }

  if(this.itinerarySelected.Ata==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    previousDate = "ETA:"+fecha;
  }

  if(this.itinerarySelected.Atd!=undefined){  
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    laterDate = "ATD:"+fecha;
  }

  if(this.itinerarySelected.Atd==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
    laterDate = "ETD:"+fecha;
  }

  return `${previousDate}, ${laterDate} - ${voyage}`;
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}

export default {
  name: "Error-Summary-Pdf",
  props: {
    VesselSituation: {
      type: Object,
      default: function () {
        return { 
          VesselSituationJson: [{
            TimeJson: [],
          }]
        }
      },
    },
    itinerarySelected: Object,
  },
  components: {},
  data,
  created,
  methods: {
    ValidateTime,
		ValidateDate,
		ValueData,
		NumberValue,
  },
  computed: {
    currentDateTime,
    titleCompany,
    titleVessel,
    titleFromItinerary,
    hasLogo,
    loginName,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  },
};
</script>

<style scoped src="./pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  /* height: 75%; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  /* margin-top: 10px; */
}
.box-center{
  display: flex;
  width: 100%;
  height: 480px;
}
.info-Situation {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;
}
.wrapper-box {
	border: 1px solid #000;
	border-radius: 10px;
	padding: 8px 15px 8px;
}
.vessel-date{
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: auto 0;
}
.vessel-row {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: auto 0;
}
.input-Bin {
	font-weight: bold;
	color: #ec631b;
}
.input-transport {
	font-weight: bold;
	color: #1e3a61;
}
.input-heavy {
	font-weight: bold;
	color: #5797e6;
}
</style>